<template>
 <div :class="windowClass">
   <v-container>
        <v-toolbar>
          <v-icon left>mdi-chart-tree</v-icon> Reports 
           <v-spacer/>
            <span style="width: 290px; margin-top: 24px; padding: 2px;">
            <v-autocomplete 
                    dense
                    outlined
                      v-model="report_type"
                    :items="TYPES"
                    label="Report"
                  return-object
                />
            </span>
        </v-toolbar>

        <v-row>
            <v-col cols="12">
              <report-per-cluster  :show="report_type=='GAME GROSS CLUSTER'?true:false" />
              <report-overall  :show="report_type=='GAME GROSS OVERALL'?true:false" />
              <report-per-draw :show="report_type=='GAME GROSS PER DRAW'?true:false" />
              <report-game-summary :show="report_type=='ACCOUNTS SUMMARY'?true:false" />
              <report-per-coordinator :show="report_type=='GEN. COORDINATOR DAILY SUMMARY'?true:false" />
               <report-perdraw-coor :show="report_type=='GEN. COORDINATOR PER DRAW'?true:false" />
               <!-- <report-per-account :show="report_type=='GAME GROSS PER ACCOUNT'?true:false" /> -->
            </v-col>
        </v-row>
   </v-container>
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      report_type:'GAME GROSS OVERALL',
      
      areas:[]
     // report_types:['Overall', 'Per Draw', 'Per Area'],
      
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getAreas()
        } 
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      TYPES(){
        if(this.$session.get('role') == "Administrator") {
          return ['GAME GROSS OVERALL', 'GAME GROSS CLUSTER', 'GEN. COORDINATOR DAILY SUMMARY', 'GEN. COORDINATOR PER DRAW', 'ACCOUNTS SUMMARY']
        } else {
            return ['Overall', 'Per Draw', 'Per Account']
        }
      },
      FILTERTRANSACTIONS() {
        console.log(this.selected_area)
        if(this.selected_area.Areas == undefined) {
           return this.transactions
        }
           let arrTrans =  this.transactions.reduce((res, item) =>{
                    if(item.area ==  this.selected_area.id) {
                          res.push(item)
                    }
                    return res
                }, [])
             
          if(this.selected_municipal.name == "All" ) {
            return arrTrans
          }     
          let arrmunTrans =  this.transactions.reduce((res, item) =>{
            if(item.municipal ==  this.selected_municipal.name) {
                  res.push(item)
            }
            return res
        }, [])
        if(this.selected_barangay == 'All') {
          return arrmunTrans
        }
        let arrBarTrans =  this.transactions.reduce((res, item) =>{
            if(item.barangay ==  this.selected_barangay) {
                  res.push(item)
            }
            return res
        }, [])
           console.log(this.selected_account)
        if(this.selected_account == 0) {
          return arrBarTrans
        }
        
        let arrAccountTrans =  this.transactions.reduce((res, item) =>{
            if(item.account ==  this.selected_account) {
                  res.push(item)
            }
            return res
        }, [])
        return arrAccountTrans
      },
      TOTAL_AMOUNT() {
        var dataArr = this.FILTERTRANSACTIONS
        var total = dataArr.reduce((res,item)=>res+=this.$IsNum(item.amount), 0)
        return total
      },
      FILTERACCOUNTS() {
            let arrTrans =  this.users.reduce((res, item) =>{
                    if(item.area ==  this.selected_area.id) {
                          res.push(item)
                    }
                    return res
                }, [])
     
        return arrTrans
      },
      FILTERDRAWS(){
        if(this.selected_game == 'All') {
          return this.draws
        }
          let arrTrans =  this.draws.reduce((res, item) =>{
                    if(item.game ==  this.selected_game) {
                          res.push(item)
                    }
                    return res
                }, [])
                return arrTrans
      },
      SELECTED_AREA(){
        var areadID = this.$IsNum(this.$session.get('areaID'))
          let area =  this.areas.reduce((res, item) =>{
                    if(item.id == areadID) {
                        res = item
                    }
                    return res
                }, {})
          return area
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
       getAreas(){
           this.$http.get("area/list",).then(response => {
            response.data.areas != null?this.areas = response.data.areas:this.areas =[]
            console.log(this.areas)
          }).catch(e => {
            console.log(e.data)
          })
      },
       
    }
  }
</script>